<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
      <b-img
            :src="logoImg"
            alt="Login"
          />
	</b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-4"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-2">
          <!-- <b-img
            fluid-grow
            :src="imgUrl"
            alt="Register V2"
          /> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Bonjour {{ qrcrelust.C_prenom }}
          </b-card-title>
          <b-card-text class="mb-2">
            Utilisez le formulaire ci-dessous pour réinitialiser votre mot de passe
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- password -->
			<b-form-group
			label-for="register-password"
			label="Nouveau mot de passe"
			>
				<validation-provider
				  #default="{ errors }"
				  name="Password"
				  vid="password"
				  rules="required|password"
				>
				  <b-input-group
					class="input-group-merge"
					:class="errors.length > 0 ? 'is-invalid':null"
				  >
					<b-form-input
					  id="register-password"
					  v-model="password"
					  class="form-control-merge"
					  :type="passwordFieldType"
					  :state="errors.length > 0 ? false:null"
					  name="register-password"
					/>
					<b-input-group-append is-text>
					  <feather-icon
						:icon="passwordToggleIcon"
						class="cursor-pointer"
						@click="togglePasswordVisibility"
					  />
					</b-input-group-append>
				  </b-input-group>
				  <small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
			</b-form-group>
			<b-form-group>
				<label>Saisir à nouveau votre nouveau mot de passe</label>
				<validation-provider
				#default="{ errors }"
				rules="required"
				>
				<b-form-input
				v-model="password2"
				type="password"
				:state="errors.length > 0 ? false:null"
				/>
				<small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
			</b-form-group>

              <b-button
				v-if="qrcrelust"
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Valider
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { useRouter } from '@core/utils/utils'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
		qrcrelust: {},
		locale: 'fr',
		password: '',
		password2: '',
		sideImg: '',
		logoImg: require('@/assets/images/logo/LOGOREUNIBOX.png'),
		// validation
		required,
		email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = ''
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
	const UIUSER = this.UI
	this.$http.get(`/tb/qrccheck/${UIUSER}`)
      .then(response => {
        this.qrcrelust = response.data
      })
  },
  methods: {
    register() {
		if (this.password === this.password2) {
		    this.$refs.registerForm.validate().then(success => {
			if (success) {
				this.$http.post('/auth/restpassword', { password: this.password, UI: this.UI })
				.then(response => {
					 if (response.data === 1) {
						 this.$router.push('/login') 
					 }
				})
				.catch(error => {
				  this.$refs.registerForm.setErrors(error.response.data.error)
				})
			} 
		  })		
		} else {
			this.$toast({
				component: ToastificationContent,
					props: {
						title: 'Attention',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
						text: 'Attention les deux mots de passe ne sont pas identiques',
					},
				},
				{
				timeout: 8000,
				position: 'top-center',
			})										
		}
    },
  },
  setup() {
	const { router } = useRouter()
	const UI = router.currentRoute.params.id
    return {
		UI,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
